
.reason{
    position: relative;
}


.reason-header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    color: white;

} 

.reason-cont{
    width: 90%;
    padding: 4rem;
}

.reason-content{
    display: flex;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    height: 63vh;
    /* background-color: blue; */
}

.reason-icon:hover{
    background-color: orangered;
    transition: 0.3s ease-in-out;
}


.reason-icon{
    padding: 1rem;
    width: 30%;
    border: 3px solid orangered;
    color: white;
    align-items: center;
}

.home-icon{
    color: white;
    font-size: 2rem;
    width: 100%;
    margin-top: 1rem;
} 

/* .home-icon:hover{
    background-color: white;
} */

.reason-contents>span{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-size: 20px;
    font-weight: bold;
    
}

.reason-contents>div{
    background-color: white;
    width: 6%;
    height: 1vh;
    margin-left: 10.3rem;
    margin-top: 1rem;
}

.reason-contents>h3{
    font-size: 15px;
    display: flex;
    justify-content: center;
    line-height: 1.5rem;
    letter-spacing: .5px;
    text-align: center;
}




/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
    .reason-cont{
        padding: 3rem;
    }

    .reason-contents>div{
        margin-left: 7rem;
    }
}


/* TABLETVIEW 1024 */

@media screen and (max-width: 1024px){
    .reason-contents>h3{
        line-height: 1.3rem;
    }

    .reason-contents>div{
        margin-left: 7rem;
    }


}


@media screen and (max-width:768px){
    .reason-header{
        gap: 2rem;
        font-size: 2.5rem;
        margin-top: 2rem;
    }

    .reason-content{
        display: flex;
        flex-direction: column;
        margin-top: 18.5rem;
        gap: 2rem;

    }


    .reason-icon{
        position: relative;
        width: 75%;
        margin-left: 2.5rem;
    }

    .home-icon{
        font-size: 2rem;
    }

    .reason-contents>span{
        font-size: 20px;
    }


    .reason-contents>h3{
        font-size: 16px;
        line-height: 1.5rem;
        letter-spacing: 1px;
    }


    .reason-contents>div{
        margin-left: 15.2rem;
    }

    .reason-cont{
        padding-bottom: 23rem;
    }


    /* SCREN 480 */

     @media screen and (max-width:480px){
        .reason-header{
            font-size: 2.5rem;
            margin-top: 2rem;
        }

        .reason-icon{
            margin-left: 0;
        }

        .home-icon{
            font-size: 2rem;
        }

        .reason-contents>span{
            font-size: 20px;
        }

        .reason-contents>div{
            margin-left: 8.9rem;
        }

        .reason-contents>h3{
            font: 16px;
            line-height: 1.2rem;
        }
    } 



    /* SCREEN 320 */

    @media screen and (max-width:320px){
        .reason{
            bottom: 10rem;
        }

        .reason-icon{
            margin-left: -1rem;
        }

        .home-icon{
            font-size: 20px;
        }

        .reason-cont{
            padding-bottom: 31rem;
        }
        .reason-content{
            margin-top: 28.5rem;
        }

        .reason-contents>div{
            margin-left: 6.3rem;
        }

        .reason-contents>span{
            font-size: 17px;
        }

        .reason-contents>h3{
            font-size: 14px;
            letter-spacing: 1.2px;
        }

    }


  


  


}







