.banner{
    display: flex;
    justify-content: space-between;
}

.left-s{
    padding: 1rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-s{
    flex: 1;
    background-color: orangered;
    position: relative;
}

.basketball-ad{
    margin-top: 4rem;
    background-color: #3d3642;
    border-radius: 4rem;
    width: fit-content;
    padding: 16px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.basketball-ad>span{
    z-index: 2;
}

.basketball-ad>div{
    position: absolute;
    background-color: orangered;
    width: 4.5rem;
    height: 70%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.banner-text{
    display: flex;
    flex-direction: column;
    gap: .7rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    text-overflow: inherit;
    font-weight: bold;
    color: #fff;
}

.banner-text>div:nth-of-type(3){
    font-size: 1rem;
    color: orangered;
    font-size: 200;
    width: 65%;
    letter-spacing: 1px;
    /* text-transform: none; */
}

.banner-btn{
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.banner-btn>:nth-child(1){
    color: white;
    background-color: orangered;
    width: 8rem;
}


.banner-btn>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orangered;
}

.bball-img{
    width: 12rem;
    position: absolute;
    right: -1rem;
    top: 1rem;

}

.bball-img2{
    position: absolute;
    width: 22.4rem;
    top: 3.6rem;
    right: 9rem;
}

.bball-img3{
    position: absolute;
    width: 11rem;
    top: 4rem;
    right: 25rem;
    z-index: -1;
}

.h-blur{
    width: 22rem;
    height: 30rem;
    left: 0;
} 


/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
   .bball-img2{
    width: 21rem;
    top: 5.9rem;
    right: 6rem;
   }

   .bball-img3{
    width: 10rem;
    top: 5rem;
   }

}



/* TABLETVIEW 1024px */


@media screen and (max-width: 1024px){
   .bball-img{
        width: 10rem;
   }

   .bball-img2{
    width: 20.2rem;
   }

   .bball-img3{
    width: 10rem;
    top: 5rem;
   }

}


/* MobileView 769 */

/* @media screen and (max-width:769px){
    .banner{
        flex-direction: column;
    }

    .h-blur{
        width: 44rem;
    }

    .basketball-ad{
        margin-top: 0;
        align-self: center;
        transform: scale(0.8);
    }
} */


/* MobileView 768 */

@media screen and (max-width:768px){
    .banner{
        flex-direction: column;
        height: 120vh;
    }

    .h-blur{
        width: 44rem;
    }

    .basketball-ad{
        margin-top: 0;
        align-self: center;
        transform: scale(0.8);
    }

    .basketball-ad>span{
        font-size: 18px;
    }

    .banner-text{
        font-size: 50px;
        align-items: center;
        justify-content: center;
    }

    .banner-text>div:nth-of-type(3){
        font-size: 20px;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }

    .banner-btn{
        justify-content: center;

    }

    .right-s{
        position: relative;
        background: none;
    }

    .bball-img{
        display: none;
    }

    .bball-img2{
        position: relative;
        width: 18rem;
        left: 22rem;
        top: -0.3rem;
        align-self: center;
    }

    .bball-img3{
        width: 10rem;
        left: 12rem;
        margin-top: -4.5rem;
    }

   

    /* SCREEN 480 */
    @media screen and (max-width:480px){
        .basketball-ad>span{
            font-size: 18px;
        }

        .banner-text{
            font-size: 50px;
        }

        .banner-text>div:nth-child(3){
            font-size: 17px;
        }

        .bball-img2{
            width: 18rem;
            left: 10rem;
            top: 0.7rem;
        }

        .bball-img3{
            width: 10rem;
            left: 2rem;
            margin-top: -4rem;
        }
    } 

    



    /* SCREEN 320 */


    @media screen and (max-width:320px){
        .bball-img2{
            width: 14rem;
            left: 7rem;
            top: 2rem;
        }

        .bball-img3{
            width: 8rem;
            margin-top: -3rem;
            left: 1rem;
        }

        .basketball-ad>span{
            font-size: 15px;
        }

        .banner-text>div>:nth-child(1){
            display: flex;
            justify-content: center;
        }
    }



    

    












}







