.copy-right{
    margin-top: -5rem;
    font-size: 20px;
    color: orangered;
    /* display: flex;
    justify-content: center; */
}


@media screen and (max-width:768px){
    .copy-right{
        margin-top: -5rem;
        font-size: 15px;
        color: orangered;
        /* background-color: black; */
    }

    svg{
        font-size: 13px;
    }

}


