.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    position: relative;
}

.program-header{
    display: flex;
    gap: 10rem;
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    justify-content: center;
}

.response-container{
    width: 100%;
    display: flex;
    height: 93vh;
    margin-left: .5rem;
}

.row{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.col-md-4{
    width: 100%;
    height: 92vh;
}

.response{
    border-radius: 10px;
    border: 10px;
    overflow: hidden;
    height: 92vh;
    width: 95%;
}

.response-bg{
    width: 100%;
    height: 22rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.3s ease-in-out;

}

.response-content{
    background-color: orangered;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 46vh;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: -65px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    clip-path: polygon(0 0, 100% 21%, 100% 100%, 0% 100%);
    color: white;
}

.response-content>span{
    text-transform: uppercase;
    letter-spacing: .2rem;
    font-style: italic;
}

.response-content>span:nth-child(1){
    font-weight: bold;
    font-size: 16px;
}


.response-content>.btn{
    padding: 1px;
    width: 5rem;
    margin-bottom: -35px;
    border-radius:10px;
}

.response:hover .response-bg{
    transform: scale(1.1);
}

.response:hover .response-content>.btn{
    padding: 1px;
    background: orangered;
    color: #fff;
    border: 1px solid  white;

}

.p-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
} 

.p-blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0;
} 


/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
   .program-header{
    gap: 4rem;
   }

   .response-content{
        margin-top: -70px;
    }

    .response-content>span:nth-child(1){
        font-size: 10px;
    }

    .response-content>span{
        font-size: 12px;
    }
}


/* TABLETVIEW 1024 */

@media screen and (max-width: 1024px){
    .program-header{
        gap: 5rem;
        font-size: 2.5rem;
    }

    .row{
        gap: .5rem;
    }    

    .col-md-4{
        height: 87vh;
    }

    .response{
        height: 87vh;
    }

    .response-content{
        margin-top: -70px;
    }

    .response-content>span:nth-child(1){
        font-size: 10px;
        width: 100%;
        margin-left: 1rem;
    }

    .response-content>span{
        font-size: 12px;
    }


}


    /* SCREENVIEW 768 */

@media screen and (max-width:768px){
    
    .program-header{
        flex-direction: column;
        gap: .5rem;
        font-size: 2rem;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
    }

    .response-container{
        position: relative;
        height: 345vh;
        
    }

    .row{
        position: absolute;
        flex-direction: column;
    }

    .col-md-4{
        width: 75%;
    }

    .response{
        height: 81vh;
        width: 100%;
    }

    .response-bg{
        height: 20rem;
        
    }

    .response-content{
        height: 45vh;
        margin-top: -70px;
    }

    .response-content>span:nth-child(1){
        font-weight: bold;
        font-size: 20px;
        margin-left: 10rem;
    }

    .response-content>span{
        font-size: 15px;
    }


    /* SCREENSIZE 480 */


    @media screen and (max-width: 480px){
        .response-content>span:nth-child(1){
            font-size: 16px;
            margin-left: 2rem;
        }

        .response-content>span{
            font-size: 14px;
        }

        .response-container{
            height: 350vh;
        }
    } 



    /* SCREENSIZE 320 */


    @media screen and (max-width:320px){
        .programs{
            padding: 2rem;
        }
        .program-header{
            font-size: 1.5rem;
        }

        .response{
            height: 82vh;
        }

        .response-container{
            height: 355vh;
        }

        .response-content{
            height: 45vh;
            margin-top: -70px;
        }

        .response-content>span{
            font-size: 12px;
        }

        .response-content>span:nth-child(1){
            font-size: 12px;
            margin-left: .8rem;
        }

        .col-md-4{
            width: 100%;
            height: 83vh;
        }

    } 

    
}