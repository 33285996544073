.about-container{
    width: 95%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.about-text{
    width: 90%;
    margin-top: 6rem;
}

/* .about-content{
    width: 50%;
} */

.about-text>span{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    margin-top: -3rem;
    justify-content: center;
    color: orangered;
}

.about-text>h2{
    font-size: 1.5rem;
    text-align: center;
    line-height: 2rem;
    letter-spacing: .2px;
    color: white;
}

.about-img{
    /* max-width: 500px; */
    width: 45%;
    border: 2px solid orangered;
    border-radius: 10px;
}


/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
   .about-text>span{
        font-size: 1.7rem;
    }

   .about-text>h2{
        font-size: 1.2rem;
    }

   .about-img{
        width: 46%;
    }

   .about-text{
        margin-top: 4rem;
    }
 
}


/* TABLETVIEW 1024px */


@media screen and (max-width: 1024px){
    .about-text{
        margin-top: 4rem;
    }

    .about-text>span{
        font-size: 1.7rem;
    }

    .about-text>h2{
        font-size: 1.2rem;

    }

    .about-container{
        width: 94%;
    }


}


@media screen and (max-width:768px){
    .about-container{
        width: 100%;
        display: block;
        /* margin-top: -1.5rem; */
    }


    .about-text>span{
        font-size: 2rem;
    }

    .about-text>h2{
        font-size: 1.2rem;
        letter-spacing: .2px;
        line-height: 1.9rem;
        width: 100%;
        text-align: center;
    }

    .about-img{
        width: 90%;
    } 
}





/* SCREENSIZE 480 */


@media screen and (max-width: 480px){
    .about-text>span{
        font-size: 1.5rem;
    }

    .about-text>h2{
        font-size: 1rem;
    }

    .about-img{
        margin-left: -.4rem;
    }
} 




    /* SCREENSIZE 320 */


@media screen and (max-width:320px){
    .about-text{
        margin-left: -.5rem;
    }
    .about-text>h2{
        font-size: 1rem;
        text-align: start;
    }

    .about-img{
        width: 90%;
        margin-left: -1rem;
    } 
}












