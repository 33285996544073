.join{
    display: flex;
    gap: 4rem;
}



.j-left{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    
}

.j-left>.join-text:nth-child(1){
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: white;

}


.email-container{
    display: flex;
    gap: 6rem;
    background-color: gray;
    padding: 1rem 2rem;
    width: 50%;
}

.email-container>input{
    background-color: transparent;
    outline: none;
    border: none;
    color: #D9D9D9;
}

::placeholder{
    color: #D9D9D9;
}

.j-btn{
    background-color: orangered;
    color: white;
    padding: .5rem 1rem;
    
}


.j-image{
    border: 2px solid orangered;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}


/* TABLETVIEW 1025px */


@media screen and (max-width: 1025px){
    .j-left>.join-text:nth-child(1){
        font-size: 2rem;
    }

    .j-image{
        margin-right: 2rem;
    }

    .email-container{
        gap: 7rem;
    }
}



/* TABLETVIEW 1024  */

@media screen and (max-width: 1024px){
    .join{
        padding-right: 2rem;
        gap: 5rem;
    }

    .j-left>.join-text:nth-child(1){
        font-size: 2rem;
    }

    .email-container{
        gap: 6rem;

    }
}


@media screen and (max-width:768px){
    .j-right{
        display: none;
    }

    .j-left{
        width: 70%;
        padding: 1.5rem;
    }

    .j-left>.join-text:nth-child(1){
        font-size: 2rem;
        margin-left: 8rem;
    }

    .join-text{
        margin-right: -7rem;
        
    }

    .email-container{
        width: 40%;
        gap: 13rem;
        padding: .3rem 3.5rem;
        
    }


    .j-btn{
        padding: .3rem 1rem;
    }


    /* SCREEN 480 */

    @media screen and (max-width:480px){

        .j-left>.join-text:nth-child(1){
            font-size: 2rem;
            margin-left: 0;
        }
        .email-container{
            gap: 5rem;
            margin-left: 1rem;
        }
    } 


    /* SCREEN 320 */


    @media screen and (max-width:320px){
        .email-container{
            gap: 3rem;
        }

        .join{
            padding-right: 7rem;
            margin-top: -15rem;
        }

        .j-left>.join-text:nth-child(1){
            margin-left: 0;
        }

        .email-container>input{
            margin-left: -3rem;
        }
    }





}
