.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 15rem;
    height: 4rem;
}

.header-links{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-links>li{
    position: relative;
}

.header-links>li:hover{
    cursor: pointer;
    color: orangered;
}

.header-links>li:hover::after{
    content: "";
    width: 30%;
    height: 2px;
    background: orangered;
    position: absolute;
    top: 20px;
    left: 1px;
}


/* TABLETVIEW 1025px */


    @media screen and (max-width: 1025px){
        .header-links{
            gap: 1rem;
        }

        .logo{
            width: 15rem;
            height: 3rem;
        }

    }


/* TABLETVIEW 1024px */


    @media screen and (max-width: 1024px){
        .header-links{
            gap: 1.3rem;
        }

        .logo{
            width: 13rem;
            height: 3rem;
        }

    }


/* SCREENSIZE 768 */


    @media screen and (max-width:768px){
        .header>img{
            width: 45%;
            height: 60%;
            padding-left: 1.5rem;
        }
        .header>:nth-child(2){
            position: fixed;
            right: 2rem;
            z-index: 99;
            
        } 

        .header-links{
            flex-direction: column;
            padding: 1.5rem;
            background-color: #332f2c;
        }

        .header>div{
            width: 2.5rem;
            margin-top: .5rem;
        }

        .header>div>img{
            width: 2.5rem;
            height: 2rem;
        }

    }


/* SCREENVIEW 480 */


    @media screen and (max-width: 480px){
        .header>img{
            padding-bottom: 1rem;
        }
 
        
    }


/* SCREENVIEW 320 */

    @media screen and (max-width: 320px){
       .header>img{
        padding-left: 0.5rem;
        height: 70%;
       }

       .header>:nth-child(2){
        right: 1rem;
        margin-top: 0rem;
       }



    }

